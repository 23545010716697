import React, { lazy, useEffect, useState } from 'react'

import DrawerModule from '../Drawer/Drawer'
import { Col, Form, Row } from 'antd'
import InputField from '../InputField/InputField'
import Button from '../Button/Button'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import { ReactComponent as AddClient } from '../../assets/icons/plus.svg'
import {
  addNewClients,
  editClient,
  getCompanydetailsById,
  getCompanyListService,
  getCompanyAndClientsByProjectId,
} from '../../utils/api/companyServices'
import {
  companyListSelector,
  setCompanyList,
} from '../../redux/reducer/companyListSlice'
import { useDispatch, useSelector } from 'react-redux'
import { updateCompanyBasedOptions } from '../../utils/helperFunctions/helperFunctions'
import { setCompanyBasedOptions } from '../../redux/reducer/getOptionsSlice'
import { companyDetailsSelector } from '../../redux/reducer/getCompanybyIdSlice'
import {
  PLACEHOLDER_MESSAGES,
  TOASTIFY_MESSAGES,
} from '../../utils/constants/messages'
import {
  clientNameRule,
  clientPhoneNumberRule,
  emailRule,
} from '../../utils/constants/validationRules'
import {
  YES,
  NO,
  STATUS,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NO,
  TOAST,
} from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'

const PopupConfirm = lazy(() => import('../PopupConfirm/PopupConfirm'))

const AddEditClientModule = ({
  editableData,
  projectId,
  companyId,
  isEditMode,
  open,
  onClose,
  title,
}) => {
  const [formDisabled, setFormDisabled] = useState(false)
  const [isFormDataChanged, setIsFormDataChanged] = useState(false)
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { companyDetails } = useSelector(companyDetailsSelector)
  const { appliedFilter } = useSelector(companyListSelector)

  useEffect(() => {
    setIsFormDataChanged(false)
    return form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData, isEditMode])
  // given dependency 'editableData' used to reset fields to autoPopulate data

  const onFinish = async ({ clientDetails }) => {
    setFormDisabled(true)
    if (isEditMode) {
      const response = await editClient({
        id: editableData?.id,
        ...clientDetails[0],
      })
      if (response?.status === STATUS.ok) {
        toastNotify(TOAST.success, response?.data?.message)
        setFormDisabled(false)
        onClose()
        form.resetFields()
        companyDetails && dispatch(getCompanydetailsById(companyDetails?.id))
        projectId && dispatch(getCompanyAndClientsByProjectId(projectId))
        const companyBasedOptions = await updateCompanyBasedOptions()
        dispatch(setCompanyBasedOptions(companyBasedOptions))
        const companyData = await getCompanyListService(
          DEFAULT_PAGE_NO,
          DEFAULT_PAGE_SIZE,
          appliedFilter
        )
        dispatch(setCompanyList(companyData?.data?.data))
      } else {
        setFormDisabled(false)
        toastNotify(TOAST.error, response?.data?.message)
      }
    } else {
      const response = await addNewClients(companyId, clientDetails)
      if (response?.status === STATUS.ok) {
        toastNotify(TOAST.success, response?.data?.message)
        setFormDisabled(false)
        onClose()
        form.resetFields()
        dispatch(getCompanydetailsById(companyId))
        const companyData = await getCompanyListService(
          DEFAULT_PAGE_NO,
          DEFAULT_PAGE_SIZE,
          appliedFilter
        )
        dispatch(setCompanyList(companyData?.data?.data))
        const companyBasedOptions = await updateCompanyBasedOptions()
        dispatch(setCompanyBasedOptions(companyBasedOptions))
      } else {
        setFormDisabled(false)
        toastNotify(TOAST.error, response?.data?.message)
      }
    }
  }
  const onFinishFailed = () => {
    toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedAddClientMessage)
  }
  return (
    <DrawerModule
      closable={!isPopupVisible}
      title={title}
      open={open}
      onClose={onClose}
      size={600}
    >
      <Form
        form={form}
        disabled={formDisabled}
        onFieldsChange={() => setIsFormDataChanged(true)}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.List name={'clientDetails'} initialValue={[{}]}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field) => (
                  <React.Fragment key={field.key}>
                    <div className="client-details">
                      <Row justify="space-between">
                        <Col>
                          <h3>
                            {isEditMode
                              ? 'Client Details'
                              : `Client-${field.name + 1} Details`}
                          </h3>
                        </Col>
                        <Col>
                          {!isEditMode && (
                            <Button
                              className="detete-icon"
                              title={'Delete Client'}
                              disabled={fields.length === 1}
                              onClick={() => {
                                remove(field.name)
                              }}
                              icon={<DeleteIcon />}
                            />
                          )}
                        </Col>
                      </Row>
                      <Row gutter={40}>
                        <Col md={24}>
                          <InputField
                            placeholder={PLACEHOLDER_MESSAGES.clientNameMessage}
                            label="Client Name"
                            name={[field.name, 'name']}
                            type="text"
                            required={true}
                            rules={clientNameRule}
                            existingValue={editableData?.name}
                          />
                        </Col>
                        <Col md={24}>
                          <InputField
                            placeholder={
                              PLACEHOLDER_MESSAGES.emailAddressMessage
                            }
                            label="Email"
                            name={[field.name, 'email']}
                            type="email"
                            required={true}
                            rules={emailRule}
                            existingValue={editableData?.email}
                          />
                        </Col>
                        <Col md={24}>
                          <InputField
                            placeholder={
                              PLACEHOLDER_MESSAGES.phoneNumberMessage
                            }
                            label="Phone Number"
                            name={[field.name, 'phoneNumber']}
                            type="text"
                            rules={clientPhoneNumberRule}
                            existingValue={editableData?.phoneNumber}
                          />
                        </Col>
                      </Row>
                    </div>
                  </React.Fragment>
                ))}
                {!isEditMode && (
                  <Button
                    onClick={() => {
                      add()
                    }}
                    type="link"
                    className="add-package"
                    label={`Add another client`}
                    icon={<AddClient />}
                  />
                )}
              </>
            )
          }}
        </Form.List>
        <div className="drawer-button btn-group">
          <PopupConfirm
            onConfirm={() => {
              form.resetFields()
              setIsPopupVisible(false)
              setIsFormDataChanged(false)
            }}
            onCancel={() => setIsPopupVisible(false)}
            cancelText={NO}
            okText={YES}
            title={'Do you want to discard the changes?'}
          >
            <Button
              danger
              label="Cancel"
              onClick={() => {
                setIsPopupVisible(!isPopupVisible)
              }}
              disabled={!isFormDataChanged}
            />
          </PopupConfirm>
          <Button
            type="primary"
            label="Save"
            disabled={!isFormDataChanged}
            htmlType="submit"
          />
        </div>
      </Form>
    </DrawerModule>
  )
}

export default AddEditClientModule
