import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, Checkbox, Radio, Spin } from 'antd'
import dateFormat from 'dateformat'

import './AddEditCompanyModule.scss'
import DrawerModule from '../Drawer/Drawer'
import PopupConfirm from '../PopupConfirm/PopupConfirm'
import Button from '../Button/Button'
import InputField from '../InputField/InputField'
import Textarea from '../Textarea/Textarea'
import AutoFieldComponent from '../AutoComplete/AutoComplete'
import DataTabs from '../DataTabs/DataTabs'
import {
  getCitiesFromCountryStates,
  getCountryOptions,
  getStatesFromCountry,
} from '../../utils/getOptions'
import {
  addEditCompany,
  getCompanydetailsById,
  getCompanyListService,
} from '../../utils/api/companyServices'
import {
  companyListSelector,
  setCompanyList,
} from '../../redux/reducer/companyListSlice'
import {
  getSubstringBeforeUnderscore,
  updateCompanyBasedOptions,
} from '../../utils/helperFunctions/helperFunctions'
import {
  setCompanyBasedOptions,
  staticOptionsSelector,
} from '../../redux/reducer/getOptionsSlice'
import {
  displayNameRule,
  companyNameRule,
  emailRule,
  nonRequiredPhoneNumberRule,
  accountStartDateRule,
  websiteRule,
  countryRule,
  stateRule,
  cityRule,
  globalTaxSettingRules,
  taxRateRules,
  taxTreatmentRules,
  placeOfContactRules,
  paymentTermsRules,
  trnNoRules,
  providerNameRule,
  companyRegionRule,
  currencyRule,
} from '../../utils/constants/validationRules'
import { DateField, DividerLine } from '..'
import SelectField from '../SelectField/SelectField'
import {
  PLACEHOLDER_MESSAGES,
  TOASTIFY_MESSAGES,
} from '../../utils/constants/messages'
import {
  YES,
  NO,
  DATE_FORMATS,
  STATUS,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NO,
  TOAST,
  taxApplicabilityOptions,
  booksNameFields,
  nonTaxRegion,
  taxPreferenceOptions,
  providerOptions,
  taxTreatmentIncludesTrnNo,
} from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import CcBccPopOver from '../ccBccPopOver/ccBccPopOver'
import { fetchRegionBasedCompanyOptionsService } from '../../utils/api/staticOptionsServices'

const AddEditCompanyModule = ({
  open,
  onClose,
  title,
  isEditMode,
  companyEditableData,
  isDuplicate = false,
}) => {
  const [form] = Form.useForm()
  const [formDisabled, setFormDisabled] = useState(false)
  const [choosenCountry, setChoosenCountry] = useState('')
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [isFormDataChanged, setIsFormDataChanged] = useState(false)
  const [checkedWithBooks, setCheckedWithBooks] = useState(false)
  const [refCompanyName, setRefCompanyName] = useState()
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const [regionBasedOptions, setRegionBasedOptions] = useState({
    taxRateOptions: [],
    companyOptions: [],
    currencyOptions: [],
    termsOptions: [],
    globalTaxOptions: [],
    statesOptions: [],
  })
  //Making globalTaxSetting intially disabled because we are using region = 'US' as initial value
  const [disabledFields, setDisabledFields] = useState({
    globalTaxSetting: true,
    taxCodeId: false,
  })
  const { appliedFilter } = useSelector(companyListSelector)
  const { companyRegionsOptions, companyproviderOptions } = useSelector(
    staticOptionsSelector
  )
  const countryOptions = getCountryOptions()
  const dispatch = useDispatch()
  const isAutoFillField = Boolean(isEditMode && !isDuplicate)
  const providerFieldValue = Form.useWatch('provider', form)
  const regionFieldValue = Form.useWatch('region', form)
  const taxPreferenceFieldValue = Form.useWatch('taxable', form)
  const taxTreatmentFieldValue = Form.useWatch('taxTreatment', form)
  // use region prefix value to check validation based on region
  const regionPrefixValue = getSubstringBeforeUnderscore(regionFieldValue)
  const isZohoBooksInd = Boolean(
    providerFieldValue === 'ZOHO_BOOKS' && regionFieldValue?.startsWith('IND')
  )
  const fetchRegionBasedOptions = async (provider, region) => {
    if (provider && region) {
      setIsLoadingOptions(true)
      const response = await fetchRegionBasedCompanyOptionsService(
        provider,
        region
      )
      if (response?.status === STATUS.ok) {
        setRegionBasedOptions((prevState) => {
          return {
            ...prevState,
            taxRateOptions: response.data.data?.taxes,
            companyOptions: response.data.data.companies,
            currencyOptions: response.data.data.currencies,
            termsOptions: response.data.data.payment_terms,
            globalTaxOptions: response.data.data?.global_taxes,
            statesOptions: response.data.data?.states,
          }
        })
      } else {
        response?.data?.message &&
          toastNotify(TOAST.error, response?.data?.message)
        setRegionBasedOptions((prevState) => {
          return {
            ...prevState,
            taxRateOptions: [],
            companyOptions: [],
            currencyOptions: [],
            termsOptions: [],
            globalTaxOptions: [],
          }
        })
      }
      setIsLoadingOptions(false)
    }
  }
  useEffect(() => {
    const region = getSubstringBeforeUnderscore(companyEditableData?.region)
    if (isAutoFillField) {
      setRefCompanyName(companyEditableData?.refCompanyName)
      companyEditableData?.refCompanyName && setCheckedWithBooks(false)
      setDisabledFields((prevState) => {
        const newState = {
          ...prevState,
          globalTaxSetting: nonTaxRegion.includes(region),
          taxCodeId:
            companyEditableData?.taxDetails?.globalTaxSetting ===
            'Not Applicable',
        }
        return newState
      })
    }
    return () => {
      form.resetFields()
      setRefCompanyName()
      setCheckedWithBooks(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyEditableData, isEditMode, isDuplicate])

  // to fetch region based options
  useEffect(() => {
    isEditMode &&
      fetchRegionBasedOptions(
        companyEditableData?.provider,
        companyEditableData?.region
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // given dependency 'companyDetails' used to reset fields to autoPopulate data
  const selectCountryHandler = (value) => {
    form.setFieldsValue({
      state: null,
      city: null,
    })
    const states = getStatesFromCountry(value)
    setChoosenCountry(value)
    setStateOptions(states)
  }

  const selectStateHandler = (state) => {
    form.setFieldsValue({
      city: null,
    })

    const city = getCitiesFromCountryStates(choosenCountry, state)
    setCityOptions(city)
  }

  const onFinish = async (values) => {
    if (!values.hasOwnProperty('cc') && !companyEditableData?.cc) {
      toastNotify(TOAST.error, TOASTIFY_MESSAGES.ccEmailsMessage)
      return
    }
    setFormDisabled(true)
    const refCompanyName =
      values?.refCompanyName &&
      values?.refCompanyName
        ?.substring(0, values?.refCompanyName?.lastIndexOf('|'))
        .trim()

    const accStartDate = dateFormat(
      form.getFieldsValue().accStartDate,
      DATE_FORMATS.dd_mmm_yyyy
    )
    const selectedRefCompany = refCompanyName?.length
      ? regionBasedOptions.companyOptions?.find(
          (company) => company?.companyName === refCompanyName
        )
      : {}
    const paymentTerms = regionBasedOptions.termsOptions?.filter(
      (term) => term?.label === values?.paymentTerms
    )
    const selectedCurrency = regionBasedOptions.currencyOptions?.filter(
      (currency) => currency?.currencyCode === values?.currencyCode
    )
    const getAppliedTaxDetails = () => {
      if (!values?.taxCodeId && !values?.taxTreatment) {
        return []
      }

      return (
        regionBasedOptions.taxRateOptions?.filter((item) => {
          return values.taxCodeId
            ? item.taxCodeId === values.taxCodeId
            : item.taxTreatment === values.taxTreatment
        }) || []
      )
    }
    const appliedTaxDetails = getAppliedTaxDetails()
    const billingAddress = {
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      state: values.state,
      country: values.country,
      zipCode: values.zipCode,
    }
    delete values.addressLine1
    delete values.addressLine2
    delete values.city
    delete values.state
    delete values.country
    delete values.zipCode
    // While edit company details
    if (isAutoFillField) {
      const data = {
        ...values,
        taxDetails: {
          ...appliedTaxDetails[0],
          globalTaxSetting: values?.globalTaxSetting,
          trnNo: values?.trnNo,
          taxable: values?.taxable ?? false,
          taxExemptReason: values?.taxExemptReason,
          panNo: values?.panNo,
        },
        billingAddress: {
          ...billingAddress,
        },
        refCompanyId: selectedRefCompany?.id,
        refCompanyName: selectedRefCompany?.companyName,
        id: companyEditableData?.id,
        modifyInBooks: checkedWithBooks,
        accStartDate,
        paymentTerms: paymentTerms[0],
        currencyId: selectedCurrency[0]?.currencyId,
        cc: values?.cc ?? companyEditableData?.cc,
        bcc: values?.bcc ?? companyEditableData?.bcc,
      }
      delete data?.globalTaxSetting
      delete data?.taxCodeId
      delete data?.gstTreatment
      delete data?.gstNo
      delete data?.taxable
      delete data?.taxExemptReason
      const response = await addEditCompany(data)
      if (response?.status === STATUS.ok) {
        dispatch(getCompanydetailsById(companyEditableData?.id))
        onClose()
        form.resetFields()
        const companyData = await getCompanyListService(
          DEFAULT_PAGE_NO,
          DEFAULT_PAGE_SIZE,
          appliedFilter
        )
        dispatch(setCompanyList(companyData?.data?.data))
        toastNotify(TOAST.success, response?.data?.message)
        setFormDisabled(false)
        setIsFormDataChanged(false)
        const companyBasedOptions = await updateCompanyBasedOptions()
        dispatch(setCompanyBasedOptions(companyBasedOptions))
      } else {
        setFormDisabled(false)
        toastNotify(
          TOAST.error,
          response?.data?.message ||
            TOASTIFY_MESSAGES.failedAddEditCompanyMessage
        )
      }
    }
    // While add new company details
    else {
      const taxDetails = appliedTaxDetails[0]
      const data = {
        ...values,
        taxDetails: {
          ...taxDetails,
          globalTaxSetting: values?.globalTaxSetting,
          trnNo: values?.trnNo,
          taxable: values?.taxable ?? false,
          taxExemptReason: values?.taxExemptReason,
          panNo: values?.panNo,
        },
        billingAddress: {
          ...billingAddress,
        },
        refCompanyId: selectedRefCompany?.id,
        refCompanyName: selectedRefCompany?.companyName,
        modifyInBooks: checkedWithBooks,
        currencyId: selectedCurrency[0]?.currencyId,
        accStartDate,
        paymentTerms: paymentTerms[0],
      }

      delete data?.globalTaxSetting
      delete data?.taxCodeId
      delete data?.gstTreatment
      delete data?.gstNo
      delete data?.taxable
      delete data?.taxExemptReason

      const response = await addEditCompany(data)
      if (response?.status === STATUS.ok) {
        setFormDisabled(false)
        toastNotify(TOAST.success, response?.data?.message)
        onClose()
        form.resetFields()
        const response2 = await getCompanyListService(
          DEFAULT_PAGE_NO,
          DEFAULT_PAGE_SIZE,
          appliedFilter
        )
        fetchRegionBasedOptions(values.provider, values.region)
        dispatch(setCompanyList(response2?.data?.data))
        const companyBasedOptions = await updateCompanyBasedOptions()
        dispatch(setCompanyBasedOptions(companyBasedOptions))
      } else {
        setFormDisabled(false)
        toastNotify(
          TOAST.error,
          response?.data?.message ||
            TOASTIFY_MESSAGES.failedAddEditCompanyMessage
        )
      }
    }
  }
  const onFinishFailed = (errorInfo) => {
    const ccBccEmailErrors = errorInfo?.errorFields?.filter(
      (error) => error?.name[0] === 'cc' || error?.name[0] === 'bcc'
    )
    for (const error of ccBccEmailErrors) {
      error?.errors?.length &&
        toastNotify(TOAST.error, `${error?.name[0]} : ${error?.errors[0]}`)
    }
  }
  const qbRegionChangeHandler = (value) => {
    const regionPrefixValue = getSubstringBeforeUnderscore(value)
    setDisabledFields((prevState) => {
      const newState = {
        ...prevState,
        globalTaxSetting: nonTaxRegion.includes(regionPrefixValue),
        taxCodeId: regionPrefixValue === 'UAE',
      }
      return newState
    })
    setRefCompanyName(null)
    fetchRegionBasedOptions(providerFieldValue, value)
  }

  const globalTaxSettingChangeHandler = (value) => {
    setDisabledFields((prevState) => {
      const newState = {
        ...prevState,
        taxCodeId: value === 'Not Applicable',
      }
      return newState
    })
  }
  const onCompanyFieldsChange = (changedFields, allFields) => {
    const isSetDisplayName = changedFields.some((field) =>
      booksNameFields.includes(field?.name[0])
    )
    setIsFormDataChanged(true)
    const filteredFormFields = allFields.filter((obj) =>
      obj.name.some(
        (n) =>
          n === 'title' ||
          n === 'firstName' ||
          n === 'middleName' ||
          n === 'lastName' ||
          n === 'suffix'
      )
    )
    const fullNameArray = filteredFormFields
      .map((obj) => obj.value?.trim() || '')
      .filter(Boolean)
    const displayName = fullNameArray.join(' ')
    isSetDisplayName &&
      form.setFields([
        {
          name: 'displayName',
          value: displayName,
          errors: [],
        },
      ])
  }
  // will reset fields while change provider/region field
  const resetBooksFields = () => {
    form.setFieldsValue({
      refCompanyName: null,
      paymentTerms: null,
      globalTaxSetting: null,
      taxCodeId: null,
      gstTreatment: null,
      gstNo: null,
      placeOfContact: null,
      panNo: null,
      taxable: true,
      taxExemptReason: null,
    })
  }
  const emailLabel = (
    <div className="email-label">
      <label className="email-add">Email Address</label>
      <CcBccPopOver
        isEditMode={isEditMode}
        companyEditableData={companyEditableData}
      />
    </div>
  )

  const items = [
    {
      label: isEditMode
        ? isDuplicate
          ? 'Duplicate Company'
          : 'Edit Company'
        : 'New Company',
      key: 1,
      children: [
        <Form
          className="new-company-info"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          disabled={formDisabled}
          onFieldsChange={(changedFields, allFields) =>
            onCompanyFieldsChange(changedFields, allFields)
          }
          scrollToFirstError
        >
          <h3>Company Information</h3>
          <div className="client-information">
            <Row gutter={20}>
              <Col md={4}>
                <InputField
                  name="title"
                  label="Title"
                  existingValue={
                    isAutoFillField ? companyEditableData?.title : undefined
                  }
                  type="text"
                />
              </Col>
              <Col md={6}>
                <InputField
                  name="firstName"
                  label="First Name"
                  existingValue={
                    isAutoFillField ? companyEditableData?.firstName : undefined
                  }
                  type="text"
                />
              </Col>
              <Col md={5}>
                <InputField
                  name="middleName"
                  label="Middle Name"
                  existingValue={
                    isAutoFillField
                      ? companyEditableData?.middleName
                      : undefined
                  }
                  type="text"
                />
              </Col>
              <Col md={6}>
                <InputField
                  name="lastName"
                  label="Last Name"
                  existingValue={
                    isAutoFillField ? companyEditableData?.lastName : undefined
                  }
                  type="text"
                />
              </Col>
              <Col md={3}>
                <InputField
                  name="suffix"
                  label="Suffix"
                  existingValue={
                    isAutoFillField ? companyEditableData?.suffix : undefined
                  }
                  type="text"
                />
              </Col>
              <Col md={24}>
                <InputField
                  name="displayName"
                  label="Display Name"
                  placeholder={PLACEHOLDER_MESSAGES.displayNameMessage}
                  existingValue={
                    isAutoFillField
                      ? companyEditableData?.displayName
                      : undefined
                  }
                  type="text"
                  required
                  rules={displayNameRule}
                />
              </Col>
              <Col md={24}>
                <InputField
                  name="companyName"
                  label="Company Name"
                  placeholder={PLACEHOLDER_MESSAGES.companyNameMessage}
                  existingValue={isEditMode && companyEditableData?.companyName}
                  type="text"
                  required
                  rules={companyNameRule}
                />
              </Col>
              <Col md={24}>
                <InputField
                  name="email"
                  label={emailLabel}
                  existingValue={isEditMode && companyEditableData?.email}
                  placeholder={PLACEHOLDER_MESSAGES.companyEmailMessage}
                  rules={emailRule}
                />
              </Col>
              <Col md={24}>
                <InputField
                  name="phoneNumber"
                  label="Phone Number"
                  existingValue={
                    isEditMode &&
                    (companyEditableData?.phoneNumber === '-'
                      ? undefined
                      : companyEditableData?.phoneNumber)
                  }
                  placeholder={PLACEHOLDER_MESSAGES.phoneNumberMessage}
                  type="text"
                  rules={nonRequiredPhoneNumberRule}
                />
              </Col>
              <Col md={24}>
                <DateField
                  name="accStartDate"
                  label="Acc Start Date"
                  type="text"
                  placeholder={PLACEHOLDER_MESSAGES.accountStartDateMessage}
                  existingValue={
                    isEditMode &&
                    new Date(companyEditableData?.accStartDate).getFullYear() >
                      1923 &&
                    companyEditableData?.accStartDate
                  }
                  required
                  rules={accountStartDateRule}
                />
              </Col>
              <Col md={24}>
                <Textarea
                  name="website"
                  label="Website"
                  placeholder={PLACEHOLDER_MESSAGES.websiteMessage}
                  existingValue={
                    isEditMode &&
                    (companyEditableData?.website === '-'
                      ? ''
                      : companyEditableData?.website)
                  }
                  type="text"
                  rules={websiteRule}
                />
              </Col>
              <Col md={12}>
                <AutoFieldComponent
                  name="country"
                  label="Country"
                  placeholder={PLACEHOLDER_MESSAGES.countryMessage}
                  type="text"
                  onSelect={selectCountryHandler}
                  existingValue={
                    isEditMode && companyEditableData?.billingAddress?.country
                  }
                  options={countryOptions}
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input?.toLowerCase())
                  }
                  required
                  rules={countryRule}
                />
              </Col>
              <Col md={12}>
                <AutoFieldComponent
                  name="state"
                  label="State"
                  placeholder={PLACEHOLDER_MESSAGES.stateMessage}
                  existingValue={
                    isEditMode &&
                    (companyEditableData?.billingAddress?.state === '-'
                      ? ''
                      : companyEditableData?.billingAddress?.state)
                  }
                  type="text"
                  onSelect={selectStateHandler}
                  options={stateOptions}
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input?.toLowerCase())
                  }
                  rules={stateRule}
                />
              </Col>
              <Col md={12}>
                <AutoFieldComponent
                  name="city"
                  label="City"
                  placeholder={PLACEHOLDER_MESSAGES.cityMessage}
                  type="text"
                  existingValue={
                    isEditMode &&
                    (companyEditableData?.billingAddress?.city === '-'
                      ? ''
                      : companyEditableData?.billingAddress?.city)
                  }
                  options={cityOptions}
                  Row
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input?.toLowerCase())
                  }
                  rules={cityRule}
                />
              </Col>
              <Col md={12}>
                <Textarea
                  name="addressLine1"
                  label="Address Line 1"
                  placeholder={PLACEHOLDER_MESSAGES.addressLine1Message}
                  type="text"
                  existingValue={
                    isEditMode &&
                    (companyEditableData?.billingAddress?.addressLine1 === '-'
                      ? ''
                      : companyEditableData?.billingAddress?.addressLine1)
                  }
                />
              </Col>
              <Col md={12}>
                <Textarea
                  name="addressLine2"
                  label="Address Line 2"
                  placeholder={PLACEHOLDER_MESSAGES.addressLine1Message}
                  existingValue={
                    isEditMode &&
                    (companyEditableData?.billingAddress?.addressLine2 === '-'
                      ? ''
                      : companyEditableData?.billingAddress?.addressLine2)
                  }
                  type="text"
                />
              </Col>
              <Col md={12}>
                <InputField
                  name="zipCode"
                  label="Zipcode"
                  placeholder={PLACEHOLDER_MESSAGES.zipCodeMessage}
                  type="text"
                  existingValue={
                    isEditMode &&
                    (companyEditableData?.billingAddress?.zipCode === '-'
                      ? ''
                      : companyEditableData?.billingAddress?.zipCode)
                  }
                />
              </Col>
            </Row>

            <DividerLine
              dividerText={`${providerFieldValue} details`}
              textOrientation="left"
              orientationMargin={16}
            />
            <Spin spinning={isLoadingOptions}>
              <Row gutter={20}>
                <Col md={12}>
                  <SelectField
                    name="provider"
                    label="Provider Name"
                    placeholder={PLACEHOLDER_MESSAGES.providerNameMessage}
                    onChange={(value) => {
                      fetchRegionBasedOptions(value, regionFieldValue)
                      resetBooksFields()
                    }}
                    required
                    rules={providerNameRule}
                    options={companyproviderOptions}
                    existingValue={
                      isAutoFillField
                        ? companyEditableData?.provider
                        : providerOptions[0]
                    }
                  />
                </Col>
                <Col md={12}>
                  <SelectField
                    name="region"
                    label="Region"
                    placeholder={PLACEHOLDER_MESSAGES.regionMessage}
                    options={companyRegionsOptions[providerFieldValue]}
                    optionLabelProp="label"
                    onChange={(value) => {
                      qbRegionChangeHandler(value)
                      resetBooksFields()
                    }}
                    required
                    rules={companyRegionRule}
                    existingValue={
                      isAutoFillField ? companyEditableData?.region : undefined
                    }
                  />
                </Col>
                {Boolean(providerFieldValue && regionFieldValue) && (
                  <>
                    <Col md={12}>
                      <SelectField
                        name="refCompanyName"
                        label="Reference Company Name"
                        placeholder={
                          PLACEHOLDER_MESSAGES.referenceCompanyMessage
                        }
                        showSearch={true}
                        allowClear={true}
                        onChange={(value) => {
                          setRefCompanyName(value)
                          value
                            ? setCheckedWithBooks(false)
                            : setCheckedWithBooks(true)
                        }}
                        options={regionBasedOptions.companyOptions?.map(
                          (company) =>
                            `${company?.companyName} | ${company?.displayName}`
                        )}
                        optionLabelProp="label"
                        existingValue={
                          isAutoFillField
                            ? companyEditableData?.refCompanyName
                              ? `${companyEditableData?.refCompanyName} | ${companyEditableData?.displayName}`
                              : null
                            : undefined
                        }
                      />
                    </Col>
                    <Col md={12}>
                      <SelectField
                        name="currencyCode"
                        label="Currency"
                        showSearch
                        placeholder={PLACEHOLDER_MESSAGES.currencyMessage}
                        allowClear
                        options={regionBasedOptions.currencyOptions?.map(
                          (currency) => currency?.currencyCode
                        )}
                        required
                        rules={currencyRule}
                        existingValue={
                          isAutoFillField
                            ? companyEditableData?.currencyCode
                            : undefined
                        }
                      />
                    </Col>
                    <Col md={12}>
                      <SelectField
                        name="paymentTerms"
                        label="Payment Terms"
                        showSearch
                        placeholder={PLACEHOLDER_MESSAGES.terms}
                        allowClear
                        options={regionBasedOptions.termsOptions?.map(
                          (term) => term?.label
                        )}
                        required
                        existingValue={
                          isAutoFillField
                            ? companyEditableData?.paymentTerms?.label
                            : null
                        }
                        rules={paymentTermsRules}
                      />
                    </Col>
                    {providerFieldValue !== 'ZOHO_BOOKS' &&
                      !isLoadingOptions && (
                        <>
                          <Col md={12}>
                            <SelectField
                              name="globalTaxSetting"
                              label="Amount are: "
                              placeholder={
                                PLACEHOLDER_MESSAGES.taxApplicabilityMessage
                              }
                              showSearch={true}
                              allowClear={true}
                              disabled={disabledFields.globalTaxSetting}
                              options={taxApplicabilityOptions}
                              optionLabelProp="label"
                              existingValue={
                                isAutoFillField
                                  ? companyEditableData?.taxDetails
                                      ?.globalTaxSetting
                                  : undefined
                              }
                              onChange={(value) =>
                                globalTaxSettingChangeHandler(value)
                              }
                              required={
                                !nonTaxRegion.includes(regionPrefixValue)
                              }
                              rules={globalTaxSettingRules(
                                form.getFieldValue('region')
                              )}
                            />
                          </Col>
                          <Col md={12}>
                            <SelectField
                              name="taxCodeId"
                              label="Tax Rate"
                              placeholder={PLACEHOLDER_MESSAGES.taxMessage}
                              showSearch={true}
                              allowClear
                              isFeatured
                              options={regionBasedOptions.taxRateOptions?.map(
                                (item) => {
                                  return {
                                    label: item?.taxName,
                                    value: item?.taxCodeId,
                                  }
                                }
                              )}
                              disabled={disabledFields.taxCodeId}
                              existingValue={
                                isAutoFillField
                                  ? companyEditableData?.taxDetails
                                      ?.taxCodeId === '-'
                                    ? ''
                                    : companyEditableData?.taxDetails?.taxCodeId
                                  : undefined
                              }
                              defaultValue={
                                isAutoFillField
                                  ? companyEditableData?.taxDetails?.taxName ===
                                    '-'
                                    ? ''
                                    : companyEditableData?.taxDetails?.taxName
                                  : undefined
                              }
                              required={
                                form.getFieldValue('globalTaxSetting') &&
                                form.getFieldValue('globalTaxSetting') !==
                                  'Not Applicable'
                              }
                              rules={taxRateRules(
                                form.getFieldValue('globalTaxSetting')
                              )}
                            />
                          </Col>
                        </>
                      )}
                    {providerFieldValue === 'ZOHO_BOOKS' &&
                      !isLoadingOptions && (
                        <>
                          {!regionFieldValue?.startsWith('UK') && (
                            <Col md={12}>
                              <SelectField
                                name="taxTreatment"
                                label="Tax Treatment"
                                placeholder={
                                  PLACEHOLDER_MESSAGES.taxTreatmentMessage
                                }
                                showSearch={true}
                                allowClear
                                isFeatured
                                options={regionBasedOptions?.taxRateOptions?.map(
                                  (option) => {
                                    return {
                                      label: option.taxName,
                                      value: option.taxTreatment,
                                    }
                                  }
                                )}
                                existingValue={
                                  isAutoFillField
                                    ? companyEditableData?.taxDetails
                                        ?.taxTreatment
                                    : undefined
                                }
                                required
                                rules={taxTreatmentRules}
                              />
                            </Col>
                          )}
                          {Boolean(
                            taxTreatmentIncludesTrnNo.includes(
                              taxTreatmentFieldValue
                            ) || regionFieldValue?.startsWith('UK')
                          ) && (
                            <Col md={12}>
                              <InputField
                                name="trnNo"
                                label="TRN No"
                                type="text"
                                placeholder={PLACEHOLDER_MESSAGES.trnMessage}
                                existingValue={
                                  isAutoFillField
                                    ? companyEditableData?.taxDetails?.trnNo
                                    : undefined
                                }
                                required
                                rules={trnNoRules}
                              />
                            </Col>
                          )}
                          {!regionFieldValue?.startsWith('UK') && (
                            <Col md={12}>
                              <SelectField
                                name="placeOfContact"
                                label="Place of Supply"
                                placeholder={
                                  PLACEHOLDER_MESSAGES.placeOfSupplyMessage
                                }
                                showSearch={true}
                                allowClear
                                options={regionBasedOptions.statesOptions}
                                existingValue={
                                  isAutoFillField
                                    ? companyEditableData?.placeOfContact
                                    : undefined
                                }
                                required
                                rules={placeOfContactRules}
                              />
                            </Col>
                          )}
                        </>
                      )}
                    {isZohoBooksInd && !isLoadingOptions && (
                      <>
                        <Col>
                          <InputField
                            name="panNo"
                            label="PAN"
                            type="text"
                            placeholder={PLACEHOLDER_MESSAGES.panNoMessage}
                            existingValue={
                              isAutoFillField
                                ? companyEditableData?.taxDetails?.panNo
                                : undefined
                            }
                          />
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            name={'taxable'}
                            className="text-field"
                            label={'Tax Preference'}
                            initialValue={
                              companyEditableData?.taxDetails?.taxable ?? true
                            }
                            required
                          >
                            <Radio.Group options={taxPreferenceOptions} />
                          </Form.Item>
                        </Col>
                        {!taxPreferenceFieldValue && (
                          <Col md={12}>
                            <InputField
                              name="taxExemptReason"
                              label="Reason"
                              existingValue={
                                isAutoFillField
                                  ? companyEditableData?.taxDetails
                                      ?.taxExemptReason
                                  : undefined
                              }
                              type="text"
                              placeholder={
                                PLACEHOLDER_MESSAGES.taxExemptReasonMessage
                              }
                              required
                            />
                          </Col>
                        )}
                      </>
                    )}
                  </>
                )}
              </Row>
            </Spin>
            <Col md={24}>
              <Form.Item name={'checkedWithBooks'}>
                <Checkbox
                  disabled={refCompanyName}
                  onChange={(e) => setCheckedWithBooks(e.target.checked)}
                  checked={checkedWithBooks}
                >
                  {`Add company in ${providerFieldValue} as well`}
                </Checkbox>
              </Form.Item>
            </Col>
          </div>
          <div className="drawer-button btn-group">
            <PopupConfirm
              onConfirm={() => {
                form.resetFields()
                setIsPopupVisible(false)
                setIsFormDataChanged(false)
              }}
              onCancel={() => setIsPopupVisible(false)}
              cancelText={NO}
              okText={YES}
              title={'Do you want to discard the changes?'}
            >
              <Button
                danger
                label="Cancel"
                disabled={!isFormDataChanged}
                onClick={() => {
                  setIsPopupVisible(!isPopupVisible)
                }}
              />
            </PopupConfirm>
            <Button
              type="primary"
              label="Save"
              htmlType="submit"
              disabled={!isFormDataChanged}
            />
          </div>
        </Form>,
      ],
    },
  ]

  return (
    <DrawerModule
      open={open}
      onClose={onClose}
      size={600}
      title={title}
      closable={!isPopupVisible}
      className="company-drawer"
    >
      <DataTabs items={items} />
    </DrawerModule>
  )
}

export default AddEditCompanyModule
