import { validRegionPrefixes } from './constant'

export const VALIDATION_MESSAGES = {
  zohoProjectIdMessage: 'Please enter project id',
  zohoProjectIdPatternMessage: 'Zoho Project id must not contain whitespace',
  projectNameMessage: 'Please enter project name',
  projectNamePatternMessage: 'Project name must be minimum 3 characters long',
  projectModelMessage: 'Please enter project model',
  invoiceCycleMessage: 'Please select invoice cycle',
  invoiceTermMessage: 'Please enter invoice term',
  invoiceTermPatternMessage: 'Invoice term must be a positive number',
  payModelMessage: 'Please select pay model',
  accountTypeMessage: 'Please select account type',
  accountStartDateMessage: 'Please select account start date',
  projectStartDateMessage: 'Please select project start date',
  primaryCSMMessage: 'Please select primary csm',
  accountantMessage: 'Please select accountant',
  leadSourceMessage: 'Please select lead source',
  marketingChannelMessage: 'Please select marketing channel',
  activeBillingMessage: 'Please select active billing',
  salesmanMessage: 'Please select Salesman',
  salesmanPatternMessage: 'Name must be characters & without extra spaces',
  startDateMessage: 'Please select start date',
  endDateMessage: 'Please select end date',
  defaultRateMessage: 'Please enter default rate',
  rateMessage: 'Please enter rate',
  ratePatternMessage: 'Invalid Rate',
  zohoUserNameMessage: 'Please select user name',
  priceMessage: 'Please enter price',
  pricePattternMessage: 'Invalid price',
  billedHoursMessage: 'Please enter billed hours',
  overTimeHoursMessage: 'Please enter over time hours',
  amountMessage: 'Please enter amount',
  amountPatternMessage: 'Invalid amount entered',
  receivedMonthMessage: 'Please select received month',
  financialTypeMessage: 'Please select financial type',
  receivedAmountMessage: 'Please enter received amount',
  invoiceStatusMessage: 'Please select invoice status',
  invoiceDateMessage: 'Please select invoice date',
  invoiceDueDateMessage: 'Please select invoice due date',
  clientNameMessage: 'Please enter client name',
  emailAddressMessage: 'Please enter email address',
  emailAddressPatternMessage: 'Please enter valid email address',
  phoneNumberMessage: 'Please enter phone number',
  phoneNumberPatternMessage: 'Please enter valid phone  number',
  displayNameMessage: 'Please enter display name',
  companyNameMessage: 'Please enter company name',
  countryMessage: 'Please select country',
  countryNameMinLengthMessage: 'Country name must be minimum 3 characters',
  countryNameMaxLengthMessage: 'Country name must be maximum 30 characters',
  stateNameMinLengthMessage: 'State name must be minimum 3 characters',
  stateNameMaxLengthMessage: 'State name must be maximum 30 characters',
  cityNameMinLengthMessage: 'City name must be minimum 3 characters',
  cityNameMaxLengthMessage: 'City name must be maximum 30 characters',
  techStackMessage: 'Please enter techstack',
  invoiceAmountMessage: 'Please enter invoice amount',
  firstNameMessage: 'Please enter first name',
  firstNamePatternMessage: 'Please enter valid first name',
  lastNameMessage: 'Please enter last name',
  lastNamePatternMessage: 'Please enter valid last name',
  oldPasswordMessage: 'Please enter old password',
  newPasswordMessage: 'Please enter new password',
  nameMessage: 'Please enter name',
  userNameMessage: 'Please enter user name',
  employeeNameMessage: 'Please enter employee name',
  globalTaxSettingMessage: 'Please select tax applicability',
  taxRateMessage: 'Please select tax rate',
  userEmailMessage: 'Please enter user email',
  userRoleMessage: 'Please select user role',
  validDomainNameMessage: 'Please enter email with valid domain name',
  otpMessage: 'Please enter otp before submitting',
  websitePatternMessage: 'Enter valid website start with https:// or ftp://',
  validPasswordPatternMessage:
    'Password must be 8-20 characters long,must contain one digit, lower case alphabet, upper case alphabet and special character.',
  noWhiteSpaceInPasswordMessage: 'Passwords must not contain whitespace',
  confirmPasswordMessage: 'Please confirm your password',
  passwordsShouldBeSameMessage:
    'New password and confirm new password must be same!',
  zohoUserIdMessage: 'Please enter zoho user id',
  zohoUserIdPatternMessage: 'Zoho user id must be nine-digit number',
  passwordMessage: 'Please enter password ',
  technologyMessage: 'Please select technology',
  accuracyMessage: 'Please select accuracy',
  CSMMessage: 'Please select CSM',
  selectOrDeleteClientMessage: 'Please select or delete client',
  noEmployeeDataMessage: 'There are no more employees to add in timesheet',
  invoiceTemplateMessage: 'Please select invoice template',
  leaveTermMessage: 'Please enter leave term',
  leaveTermPatternMessage: 'Leave term must be a positive number',
  retainerMessage: 'Please enter retainer',
  retainerPatternMessage: 'Enter valid retainer value',
  categoryMessage: 'Please enter category',
  incentiveValueMessage: 'Please enter incentive value',
  accountNameMessage: 'Please select account',
  dateRangeMessage: 'Please select date range',
  messageOnInvoiceStatementMessage: 'Please write message',
  invoiceNumberMessage: 'Please enter invoice number',
  activeBalanceDateMessage: 'Please select active balance date',
  activeBalanceMessage: 'Please enter valid active balance value',
  receivedDateMessage: 'Please select receive date ',
  providerNameMessage: 'Please select provider name',
  regionsMessage: 'Please enter region',
  companyRegionMessage: 'Please select region',
  clientIdMessage: 'Please enter client id',
  clientSecretMessage: 'Please enter client secret',
  taxTreatmentMessage: 'Please select tax treatment',
  trnNoMessage: 'Please enter trn no',
  vatNoMessage: 'Please enter vat no',
  placeOfContactMessage: 'Please select place of supply',
  paymentTermsMessage: 'Please select payment terms',
  organizationNameMessage: 'Please select organization name',
  redirectURLMessage: 'Please enter redirect URL',
  scopeCodeMessage: 'Please enter scope code',
  realmIdMessage: 'Please enter organization/realm Id',
  validRegionMessage: `region should start with one of the following prefixes: "${validRegionPrefixes.join(
    ', '
  )} followed by '_'`,
  linkTitleMessage: 'Please enter title',
  linkUrlMessage: 'Please enter url',
  subMissionTypeMessage: 'Please select submission type',
  selectMonthMessage: 'Please select month to generate data',
  noSpacesAllowedMessage: 'Email address cannot contain spaces',
  invalidEmailFormatMessage: 'Please enter a valid email address',
  currencyMessage: 'Please select currency',
}

export const PLACEHOLDER_MESSAGES = {
  zohoProjectIdMessage: 'Enter Zoho project id',
  projectNameMessage: 'Enter project name',
  projectModelMessage: 'Select project model',
  invoiceCycleMessage: 'Select invoice cycle',
  invoiceTermMessage: 'Enter invoice term',
  payModelMessage: 'Select pay model',
  accountTypeMessage: 'Select account type',
  accountStartDateMessage: 'Select account start date',
  projectStartDateMessage: 'Select project start date',
  primaryCSMMessage: 'Select primary csm',
  primaryAccountantMessage: 'Select primary accountant',
  accountantMessage: 'Select accountant',
  contractDocumentMessage: 'Select contract document',
  leadSourceMessage: 'Select lead source',
  marketingChannelMessage: 'Select marketing channel',
  activeBillingMessage: 'Select active billing',
  defaultRateMessage: 'Enter default rate',
  rateMessage: 'Enter rate',
  salesmanMessage: 'Select sales person',
  startDateMessage: 'Select start date',
  endDateMessage: 'Select end date',
  zohoUserNameMessage: 'Select zoho user name',
  billedHoursMessage: 'Enter billed hours',
  overTimeHoursMessage: 'Enter over time hours',
  amountMessage: 'Enter amount',
  receivedDateMessage: 'Select received date',
  receivedMonthMessage: 'Select received month',
  financialTypeMessage: 'Select financial type',
  receivedAmountMessage: 'Enter received amount',
  invoiceStatusMessage: 'Select invoice status',
  invoiceDateMessage: 'Select invoice date',
  invoiceDueDateMessage: 'Select invoice due date',
  customerNameMessage: 'Enter customer name',
  clientNameMessage: ' Enter client name',
  emailAddressMessage: ' Enter email address',
  companyEmailMessage: 'Email (Seperate emails with comma)',
  phoneNumberMessage: ' Enter phone number',
  displayNameMessage: ' Enter display name',
  companyNameMessage: ' Enter company name',
  countryMessage: ' Select country name',
  stateMessage: 'Enter state name ',
  cityMessage: 'Select city name',
  techStackMessage: ' Enter techstack',
  invoiceAmountMessage: ' Enter invoice amount',
  firstNameMessage: ' Enter first name',
  firstNamePatternMessage: ' Enter valid first name',
  lastNameMessage: ' Enter last name',
  lastNamePatternMessage: ' Enter valid last name',
  oldPasswordMessage: ' Enter old password',
  newPasswordMessage: ' Enter new password',
  nameMessage: ' Enter name',
  userNameMessage: ' Enter user name',
  userEmailMessage: ' Enter user email',
  userRoleMessage: 'Select user role',
  websiteMessage: 'Enter web address',
  addressLine1Message: 'Enter address line 1',
  addressLine2Message: 'Enter address line 2',
  zipCodeMessage: 'Enter zip code',
  referenceCompanyMessage: 'Select reference company',
  taxMessage: 'Select tax',
  currencyMessage: 'Select currency',
  terms: 'Select terms',
  globalTaxSettingMessage: 'Select tax applicability',
  zohoRoleMessage: 'Select zoho role',
  invoiceNumberMessage: 'Enter invoice number',
  accuracyMessage: 'Select accuracy',
  accuracy: 'accuracy',
  outStandingAmountMessage: 'Outstanding amount',
  technologyMessage: 'Select technology',
  CSMMessage: 'Select CSM',
  employeeMessage: 'Select Employee',
  passwordMessage: 'Enter password ',
  invoiceTemplateMessage: 'Select invoice template',
  leaveTermMessage: 'Enter leave term',
  logLevelMessage: 'Select log level',
  notesMessage: 'Write some notes about employee',
  timesheetNoteMessage: 'Enter note about timesheet',
  leaveBalance: 'leave balance',
  billingPercentage: 'Billing Percentage',
  regionMessage: 'Select Region',
  predictionDropdownMessage: 'Select Prediction/Invoices',
  searchInvoiceMessage: 'Search by invoice number',
  exchangeRateMessage: 'Enter custome exchange rate ',
  companyOrProjectMessage: 'Select Company/Project',
  categoryMessage: 'Select category',
  inactivationMessage: 'Select inactivation date',
  searchProjectMessage: 'Search by project',
  searchByInvoiceProjectMessage: 'Search by invoice/project',
  messageOnInvoice: 'This will show up on the invoice.',
  messageOnStatement:
    'If you send statements to customers, this will show up as the description for the invoice.',
  activeBalanceMessage: 'Enter active balance',
  activeBalanceDateMessage: 'Select active balance date',
  holidayDateMessage: 'Please select date',
  holidayNameMessage: 'Enter holiday name',
  serviceMessage: 'Select Service',
  billingModelMessage: 'Select Model',
  providerNameMessage: 'Select provider name',
  clientIdMessage: 'Enter client id',
  clientSercretMessage: 'Enter client secret',
  scopeCodeMessage: 'Enter scope code',
  realmIdMessage: 'Enter organization id/realm id',
  associatedProjectNamesMessage: 'Select associated projects',
  taxTreatmentMessage: 'Select tax treatment',
  trnMessage: 'Enter trn no',
  placeOfSupplyMessage: 'Select place of supply',
  taxExemptReasonMessage: 'Enter tax exempt reason',
  panNoMessage: 'Enter pan no',
  taxApplicabilityMessage: 'Select amounts are',
  organizationNameMessage: 'Enter organization name',
  redirectURLMessage: 'Enter Redirect URL',
  selectMonthMessage: 'Select month',
  allocationTypeMessage: 'Allocation type',
  selectTaxMessage: 'Select Tax',
}

export const TOASTIFY_MESSAGES = {
  signInSuccessMessage: 'Signed in successfully',
  wrongOtpMessage: 'Wrong OTP..! Please Try Again.',
  authentcatingMessage: 'Authenticating...',
  qrCodeGeneratedMessage: 'QR code is already generated for this user.',
  userNotFoundMessage: 'User not found with this email',
  wrongCredentialMessage: 'Wrong Credentials! Please check the credentials.',
  somethingWentWrongMessage: 'Something went wrong! Please Try Again.',
  submitFailedMessage: 'Failed to submit the form, Please try again!',
  failedDeletePredictionMessage:
    'Failed to delete Prediction! Please try again.',
  passwordSuccessMessage: 'Password updated successfully!',
  sameOldPasswordMessage: 'New password can not be same as old password.',
  expiredPasswordMessage:
    'Password reset link is expired. Please generate a new link.',
  failedUpdatePasswordMessage: 'Failed to update the password',
  wentWrongMessage: 'Something went wrong !',
  updateRateMessage: 'Rates updated successfully',
  failedRateMessage: 'Failed to update the rates. Please try again',
  failedDownloadMessage: 'Download failed, Try again!',
  oneSalesmanMessage: 'At least one salesperson is required',
  uniqueNameMessage: 'Salespersons ,csm, accountant names should be unique!',
  sameSalesPersonsMessage: 'Salespersons with same names are not allowed!',
  sameCsmMessage: 'Csm with same names are not allowed!',
  sameAccountantMessage: 'Accountant with same names are not allowed!',
  failedUpdateProjectMessage:
    'validation failed while updating the project,Please check validation message!',
  failedAddClientMessage: 'Failed to add clients! Please try again.',
  failedAddInvoiceMessage: 'Failed to add invoices! Please try again.',
  receivedAmountNotEqualMessage:
    ' Received amount is not equal to Invoice amount.',
  sameAmountMessage: ' Received amount must be same as Invoice amount',
  failedAddPredictionMessage: 'Failed to add prediction! Please try again.',
  noClientsMessage: 'No clients in this company! Please add new client.',
  noClientInCompanyMessage:
    'There are no clients in selected company! Please try adding new client.',
  selectPrimaryClientMessage: 'Please select primary client.',
  companyClientSuccessMessage:
    'Company and Client assigned to project successfully',
  failedAddCompanyMessage:
    'Failed to add company and client to project! Please try again.',
  failedToAddExitingClientMessage:
    'Failed to submit the add exisiting client form, Please try again!',
  failedToAddNewClientMessage:
    'Failed to submit the add new client form, Please try again!',
  noClientsInProjectMessage: 'There are no other clients available in project',
  noOtherCsmMessage: 'There are no other CSMs available in project',
  failedChangePasswordMessage: 'Failed to change password! Please try again',
  failedToApplyFilterMessage: 'Failed to apply filter! Please try again.',
  atLeastOneFilterMessage:
    'Please select atleast one filter options to apply filter!',
  failedFetchAssignedProjects:
    'Failed to fetch already assigned projects! Please try deleting the user again!',
  failedDeleteClientMessage: 'Failed to delete client! Please try again.',
  notFindResultMessage:
    "Couldn't find the results! Please search with another keyword.",
  selectAnyoneFilterMessage:
    'please select anyone filter options to apply filter!',
  updateProfileMessage: 'Profile updated successfully',
  failedUpdateProfileMessage:
    'Failed to Update user profile! Please try again.',
  imageNotValidMessage: 'is not a valid image. Only JPG and PNG accepted.',
  failedEditPredictionMessage: 'Failed to Edit Prediction! Please try again.',
  failedVerifyTimesheetMessage: 'Failed to Verify timesheet! Please try again.',
  timesheetSuccessMessage: 'Timesheet fetched successfully!',
  validationFailedMessage:
    'validation failed ,Please check validation message!',
  failedUpdatePaymentStatusMessage:
    'Failed to update payment status! Please try again.',
  enableActiveBillingMessage: 'Please enable active billing first.',
  previousRequestMessage: 'Wait, Working on previous request.',
  failedUpdateRatesMessage:
    'Failed to update details in update Rates! Please try again.',
  receivedAmountRequireMessage: 'Received Amount must be have some value',
  receivedInvoiceAmountMessage: (data) =>
    `${data?.projectName} with Invoice Amount $${data?.invoiceAmount} must be greater than or equal to Received Amount`,
  updateInvoiceMessage: 'Invoices updated successfully',
  lessInvoiceAmountMessage:
    'Invoice amount can not be less than received amount',
  greaterReceivedAmountMessage:
    'Received amount can not be greater than invoice amount',
  invoiceNotFoundMessage: (invoiceId) =>
    `Invoice with id ${invoiceId} not found`,
  predictionNotFoundMessgage: (predictionId) =>
    `Prediction with id ${predictionId} not found`,
  logSuccessMessage: 'Logs returned successfully',
  failedAddEditUserMessage: (isEditMode) =>
    `Failed to ${isEditMode ? 'edit user' : 'add new user'}! Please try again.`,
  noDetailsChangeMessage:
    'No details changed!, Please edit data and try to update again! ',
  failedToUpdateMessage: 'Failed to update details! Please try again.',
  saveChangesInfoMessage:
    'Please save the changes before moving to another page.',
  failedAddEmployeeMessage: 'failed to add new employee,Try again!',
  addMissingFieldsMessage: (missingFields) =>
    `Please add ${missingFields} in this project.`,
  inactiveBillingMessage:
    'Billing for the given project is currently inactive! Please activate project.',
  displayedProjectsMessage: 'Projects displayed successfully',
  syncedProjectsErrorMessage: 'Problem occured while fetching synced projects',
  failedToDeleteUserMessage: 'Failed to delete User! Please try again.',
  noDataForProjectIdMessage: 'No data available for this ProjectId!',
  failedProjectNoteOperationMessage: (operation) =>
    `Failed to ${operation} project note! Please try again.`,
  emptyInputMessage: (fieldName) =>
    `Please enter ${fieldName} before submitting.`,
  projectNoteDescriptionUpdatedMessage: (updatedDescription) =>
    `Note updated with description: "${updatedDescription}". `,
  projectNoteStatusUpdatedMessage: (updatedStatus) =>
    `Note updated with status: "${updatedStatus ? 'Completed' : 'Pending'}". `,
  emptyRateDetailsOfCurrentDateMessage:
    'There are no rates for the current date.Please choose a different date range or enter new rates!',
  failedToAddEditSalesPersonsMessage: (isEditMode) =>
    `Failed to ${
      isEditMode ? 'edit' : 'add'
    } sales persons ! Please try again.`,
  notAssignedCsmMessage: 'Primary CSM is not assigned this Project',
  notAssignedAccountantMessage:
    'Primary Accountant is not assigned this Project',
  shortDurationVerificationMessge: 'Time duration too short for verification',
  unableToViewInvoiceMessage:
    'Please make sure you have added end-date to prediction !',
  ccEmailsMessage: 'ccEmails : Please enter email address',
  failedAddEditCompanyMessage:
    'Something went wrong while add/edit company details',
  employeeNameNullMessage: 'Please select employeename',
  serviceNameNullMessage: 'Please select service option',
  failedDeletetTemplateMessage: 'Failed to delete rate template !',
  copiedSuccessMessage: 'Text copied to clipboard',
  failedCopiedMessage: 'Failed to copy text to clipboard:',
  UrlNotFoundMessage: 'The URL could not be found',
  failedToAddNewLinkMessage: 'Failed to add new link !',
  failedUpdateDashboardDataMsg:
    'Failed to update dashboard data, Please try again !',
  noDataGneratedMessage: 'No data generated for this month !',
  noRateDetailsMessage: 'There is no rate details for given date range',
  noSearchedRateDetails: 'There is no rate details for searched projects',
  failedToServiceMessage: 'Failed to fetch service option !',
  noSelectedCustomInvoices:
    'Please select at least one Invoice to map with project',
  addDatesMessage: 'Please add startDate and endDate for selected Invoice list',
  failedToMapInvoiceMessage:
    'Failed to Map Invoice with project, Please try again !',
  addRatesSuccessMessage: 'Rates added successfully',
}
